import _ from "lodash";
import shippingPrices from 'components/shippingPrice';

export const getShipping = (item) => getShippingPrice(item) === '0.00' ? 'Free' : `${getShippingPrice(item)}`;

export const getShippingPrice = (item) => {
  const itemSku = _.get(item, 'variants[0].sku') || _.get(item, 'variant.sku');
  const shippingItem = _.find(shippingPrices, { sku: itemSku });
  const shippingPrice = _.get(shippingItem, 'price');

  return shippingPrice;
}

export const getItemPrice = (item) => _.get(item, 'variants[0].price.amount') || _.get(item, 'variant.price.amount');

export const getItemTotalPrice = (item) => {
  const totalPrice = parseFloat(
    getItemPrice(item)
  ).toFixed(2);

  return totalPrice;
}
