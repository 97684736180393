import React, { useEffect, useContext } from 'react';
import Welcome from './pages/Welcome';
import GlobalHeader from './components/GlobalHeader';
import GlobalFooter from './components/GlobalFooter';
import './App.scss';
import ShopProvider from './context/shopContext';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

export const App = props => {
  return (
    <ShopProvider>
      <div className="wrapper">
        <Router>
          <GlobalHeader />
          <Welcome />
          <GlobalFooter />
        </Router>
      </div>
    </ShopProvider>
  )
}


export default App;
