import React, { useContext } from 'react';
import { Drawer } from 'antd';
import { ShopContext } from '../../context/shopContext';

export const DrawerHoc = props => {
  const { closeCart, isCartOpen } = useContext(ShopContext);

  if (isCartOpen) {
    return (
      <Drawer
        title={props.title}
        footer={props.footer}
        placement="right"
        closable
        width="500"
        onClose={closeCart}
        visible={isCartOpen}
        getContainer={false}
      >
        {props.children}
      </Drawer>
    )
  }
  return <noscript />
}

export default DrawerHoc;
