import React, { Component, createContext } from "react";
import Client from "shopify-buy";
import filter from 'lodash/filter';
// import _ from 'lodash';

const ShopContext = createContext();

const client = Client.buildClient({
  storefrontAccessToken: '251729e85b2d4d5e111f8fd3a5f83e84',
  domain: "rcdepron.myshopify.com",
});

class ShopProvider extends Component {
  state = {
    product: {},
    products: [],
    collections: [],
    checkout: {},
    isCartOpen: false,
  };

  componentDidMount() {
    this.createCheckout();

    //Check if localStorage has a checkout_id saved
    // if (_.isEmpty(localStorage.checkout)) {
    //   this.fetchCheckout(localStorage.checkout);
    // } else {
    this.createCheckout();
    // }
    //if there is no checkout_id in localStorage then we will create a new checkout

    //else fetch the checkout from shopify
  }

  createCheckout = async () => {
    const checkout = await client.checkout.create();
    localStorage.setItem("checkout", checkout.id);
    await this.setState({ checkout: checkout });
  };

  fetchCheckout = async (checkoutId) => {
    client.checkout
      .fetch(checkoutId)
      .then((checkout) => {
        this.setState({ checkout: checkout });
      })
      .catch((err) => console.log(err));
  };

  addItemToCheckout = async (variantId, quantity) => {
    console.log('variantId', variantId);
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    const checkout = await client.checkout.addLineItems(
      this.state.checkout.id,
      lineItemsToAdd
    );
    this.setState({ checkout: checkout });

    this.openCart();
  };

  removeItemFromCheckout = async (itemId, variantId, quantity, itemsLeft) => {
    let checkout;
    if (itemsLeft > 1) {
      checkout = await client.checkout.addLineItems(
        this.state.checkout.id,
        [{ variantId, quantity: -1 }]
      );
      this.setState({ checkout: checkout });
    }
    if (itemsLeft === 1) {
      try {
        checkout = await client.checkout.removeLineItems(this.state.checkout.id, [itemId]);
        this.setState({ checkout: checkout });
      } catch(e) {
        console.log('e', e);
      }
    }
    else {
      console.log('too many clicks');
    }
  };

  fetchAllProducts = async () => {
    const products = await client.product.fetchAll();
    this.setState({ products });
  };

  fetchAllCollections = async () => {
    // console.log('client', client.collection);
    const collection = await client.collection.fetchAllWithProducts();
    this.setState({ collections: collection });
  };

  fetchProductWithId = async (id) => {
    const product = await client.product.fetch(id);
    this.setState({ product: product });

    return product;
  };

  closeCart = () => {
    this.setState({ isCartOpen: false });
  };
  openCart = () => {
    this.setState({ isCartOpen: true });
  };

  render() {
    return (
      <ShopContext.Provider
        value={{
          ...this.state,
          fetchAllProducts: this.fetchAllProducts,
          fetchAllCollections: this.fetchAllCollections,
          fetchProductWithId: this.fetchProductWithId,
          closeCart: this.closeCart,
          openCart: this.openCart,
          addItemToCheckout: this.addItemToCheckout,
          removeItemFromCheckout: this.removeItemFromCheckout,
        }}
      >
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;
