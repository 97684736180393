import React, { useContext } from "react";
import map from 'lodash/map';
import get from 'lodash/get';
import drop from 'lodash/drop';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import "./productShop.scss";
import { ShopContext } from '../../../context/shopContext';

const ProductShop = () => {
  const history = useNavigate();
  const { collections } = useContext(ShopContext);
  const removeFirstCollection = drop(collections, 1);
  const filteredCollections = filter(removeFirstCollection, (item) => !item.title.match(/\d+mm/) && !item.title.match(/Mixed/));

  return (
    <div className="productShopInformationWrapper">
      <div className="information">
        <div className="contentWrapper">
          <div className="content information-content">
            {/*<div className="information-about">ABOUT RCDEPRON</div>*/}
            <div className="information-h1">
              The Go-To Resource for RC Enthusiasts
            </div>
            <div className="information-h2">
              The ultimate plane building material! Lighter and easier to build with.
            </div>
          </div>
        </div>
      </div>
      <div className="productShopWrapper">
        <div className="productShopProducts">
        <div className="productShopDisclaimer">Prices may vary based on product size.</div>
          {map(filteredCollections, collection => {
            const isDescriptionVisible = !collection.descriptionHtml.includes('<ul>');

            let collectionWidth = '25%';

            if (filteredCollections) {
              if (filteredCollections.length === 2) {
                collectionWidth = '400px'
              } else if (filteredCollections.length === 3) {
                collectionWidth = '33%'
              }
            }

            return (
              <div
                className="productShopProduct"
                key={collection.title}
                onClick={() => !isEmpty(collection.products) && history(collection.handle)}
                style={{
                  cursor: isEmpty(collection.products) ? 'not-allowed' : 'pointer',
                  flex: `1 0 ${collectionWidth}`,
                  maxWidth: `calc(${collectionWidth} - 10px)`,
                }}
              >
                <div className="productShopProductImageWrapper">
                  <img src={get(collection, 'image.src')} alt={collection.title} className="productShopProductImage" draggable="false" />
                </div>
                <div className="productShopProductText">
                  <div className="productShopProductTitle">{collection.title}</div>
                  <div className="productShopProductPrice">{isDescriptionVisible && ReactHtmlParser(collection.descriptionHtml)}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductShop;
