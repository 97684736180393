import React, { useContext } from 'react';
import _ from 'lodash';
import { DrawerHoc } from '../DrawerHoc';
import { ShopContext } from '../../context/shopContext';
import './Cart.scss';
import { getItemPrice, getShippingPrice, getShipping } from 'utils/utils';

export const Cart = (props) => {
  const { checkout, addItemToCheckout, removeItemFromCheckout } = useContext(ShopContext);

  return (
    <DrawerHoc
      title="Shopping Cart"
      footer={
        <div className="buttonWrapper">
          <a
            className="button"
            href={checkout.webUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Checkout
          </a>
        </div>
      }
    >
      {_.isEmpty(checkout.lineItems) ? (
        <div>There are no items in the shopping cart</div>
      ) : (
        _.map(checkout.lineItems, item => (
          <div className="cart-wrapper">
            <img className="cart-image" src={_.get(item, 'variant.image.src')} alt="" />
            <ul className="cart-ul-description">
              <li className="item-text">{item.title}</li>
              <li className="item-text">{item.quantity} item(s)</li>
              <li className="item-text">
                Individual item cost:
                <br />${parseFloat(getItemPrice(item)).toFixed(2)} + Free Shipping/Case
              </li>
            </ul>
            <div className="cart-button-wrapper">
              <button className="button cart-button-mods"
                onClick={() => addItemToCheckout(_.get(item, 'variant.id'), 1)}
              >
                <span>+</span>
              </button>
              <button className="button cart-button-mods"
                onClick={() => removeItemFromCheckout(_.get(item, 'id'), _.get(item, 'variant.id'), -1, item.quantity)}
              >
                <span>-</span>
              </button>
            </div>
          </div>
        ))
      )}
    </DrawerHoc>
  )
}

export default Cart;
