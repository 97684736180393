import React from 'react';
import './GlobalFooter.scss';

export const GlobalFooter = props => {
  return (
    <div className="footerWrapper">
      <div className="footer">
        <div>Cool Links</div>
        <div><a href="https://dynasoarrocketry.com/">Dynasoar Rocketry</a> | <a href="https://www.buzzardmodels.com/">Buzzard Models</a></div>
        <div>&copy; 2020 Greg Tanous Lasering LLC | All rights reserved</div>
        <div>Contact: <a href="mailto:gregtanouslasering@yahoo.com">gregtanouslasering@yahoo.com</a></div>
      </div>
    </div>
  )
}

export default GlobalFooter;
