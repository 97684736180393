import React, { Fragment, useContext, useEffect } from "react";
import { ShopContext } from '../../context/shopContext';
import ProductShop from './productShop';
import ProductSelect from './productSelect';
import "./App.scss";
import {
  Routes,
  Route,
} from 'react-router-dom';

const Welcome = () => {
  const { fetchAllCollections, collections, fetchAllProducts, products, addItemToCheckout } = useContext(ShopContext);

  useEffect(() => {
      fetchAllCollections()
      return () => {
          // cleanup
      };
  }, [fetchAllCollections]);

  return (
    <div className="welcomeWrapper">
      <Routes>
        <Route path="/" element={<ProductShop />} />
        <Route path="/:id" element={<ProductSelect />} />
        <Route path="/:id/:productId/:country" element={<ProductSelect />} />
      </Routes>
    </div>
  );
}

export default Welcome;
