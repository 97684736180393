import React, { useContext } from 'react';
import './GlobalHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { ShopContext } from '../../context/shopContext';
import { Cart } from '../Cart';
import { useNavigate } from 'react-router-dom';

export const GlobalHeader = props => {
  const { openCart } = useContext(ShopContext);
  const history = useNavigate();

  return (
    <div className="header">
      <div className="logo">
        <div className="logoText" onClick={() => history('/')}>
          RcDepron
        </div>
        <div className="tagline">
          The Depron King: The US Source for Depron
        </div>
      </div>{/*end of logo*/}
      <div className="shoppingCartNavButtonWrapper">
        <div className="shoppingCartNavButton" onClick={() => openCart()}>
          <FontAwesomeIcon icon={faShoppingCart} size="2x" />
          <span className="yourCart">Cart</span>
        </div>
      </div>{/*end of shoppingCartNavButton*/}
      <Cart />
    </div>
  )
}

export default GlobalHeader;
