import React, { useContext, useState, useEffect } from "react";
import "./productSelect.scss";
import _ from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import some from 'lodash/some';
import map from 'lodash/map';
import head from 'lodash/head';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { Select } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { LockOutlined } from '@ant-design/icons';
import usFlag from 'assets/images/us-flag.png';
import canadianFlag from 'assets/images/canadian-flag.png';
import { useParams, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../../context/shopContext';
import { getItemPrice, getItemTotalPrice } from 'utils/utils';

const { Option } = Select;

const DEFAULT_COUNTRY = 'usa';

// The url and key have to be the same lol
const COUNTRY_SELECTION = {
  usa: {
    src: usFlag,
    alt: 'US Flag',
    filter: '',
    url: 'usa',
  },
  canada: {
    src: canadianFlag,
    alt: 'Canadian Flag',
    filter: 'Canadian',
    url: 'canada',
  },
};

const ProductSelect = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const history = useNavigate();
  const { collections, addItemToCheckout } = useContext(ShopContext);
  const params = useParams();
  const handle = _.get(params, 'id');
  const productIdParam = get(params, 'productId');
  const countryParam = _.get(params, 'country', COUNTRY_SELECTION[DEFAULT_COUNTRY].url);
  const collection = find(collections, collection => collection);
  const collectionItem = find(collections, collection => collection.handle === handle);
  const products = _.get(collectionItem, 'products');
  const [selectedCountry, setSelectedCountry] = useState(countryParam || COUNTRY_SELECTION[DEFAULT_COUNTRY].url);

  const filteredProducts = _.filter(products, (product) => {
    if (selectedCountry === 'usa') {
      return !product.title.match('Canadian');
    }
    return product.title.match(COUNTRY_SELECTION[selectedCountry].filter);
  }).map((product) => {
    return {
      ...product,
      id: product.id.replace(/gid:\/\/shopify\/Product\//, ''),
    }
  });
  const firstProduct = _.head(filteredProducts);
  const firstProductId = _.get(firstProduct, 'id');
  const product = find(filteredProducts, product => product.id === productIdParam) || firstProduct;

  const handleChangeProduct = (id) => {
    history(`/${handle}/${id}/${countryParam}`, { replace: true });
    setHoveredImage(0);
  }

  const handleChangeCountry = (country) => {
    setSelectedCountry(country);
    history(`/${handle}/${firstProductId}/${country}`, { replace: true });
  }

  useEffect(() => {
    if (!params.country && firstProductId) {
      history(`/${handle}/${firstProductId}/${countryParam}`, { replace: true });
      setHoveredImage(0);
    }
  }, [countryParam, firstProductId, handle, history, params.country]);

  const images = _.get(product, 'images');
  const numberSelect = _.range(1, 11);
  const [hoveredImage, setHoveredImage] = useState(0);
  const productTitle = _.get(product, 'title');
  const productPrice = getItemPrice(product);
  const formattedProductPrice = productPrice ? parseFloat(productPrice) : '';
  const hasCanadianItems = some(products, product => product.title.match('Canadian'));

  const getSheetThickness = (item) => {
    if (item.title.match(/Sheet/)) {
      return head(item.title.match(/^.*?\s\|/)).replace(/ \|/, '').replace("\(Canadian\)", '');
    } else if (item.title.match(/Mixed/)) {
      return 'Mixed'
    }
    return undefined;
  }

  const cleanedProducts = map(filteredProducts, item => {
    return {
      id: item.id,
      title: item.title,
      sheetCount: item.title.match(/Sheet/) ? head(item.title.match(/\|.*? Sheet/)).replace(/\| /, '').replace(/ Sheet/, '') : undefined,
      sheetSize: item.title.match(/Sheet/) ? head(item.title.match(/Sheets \| .*?\|/)).replace(/\| /, '').replace(/" \|/, '').replace(/Sheets /, '') : undefined,
      sheetThickness: getSheetThickness(item),
    };
  });
  const cleanedSheetThicknessProducts = groupBy(cleanedProducts, cleaned => get(cleaned, 'sheetThickness'));
  const orderedSheetThicknessProducts = orderBy(Object.keys(cleanedSheetThicknessProducts));
  const selectedCleanedProduct = product ? find(cleanedProducts, { id: product.id }) : '';
  const filteredSheetCountProducts = filter(cleanedProducts, { sheetThickness: selectedCleanedProduct.sheetThickness })
  const cleanedSheetCountProducts = groupBy(filteredSheetCountProducts, cleaned => get(cleaned, 'sheetCount'));
  const orderedSheetSizeProducts = orderBy(cleanedSheetCountProducts[selectedCleanedProduct.sheetCount], ordered => get(ordered, 'sheetSize') ? get(ordered, 'sheetSize').replace("~", '') : undefined);
  const orderedMixedProducts = groupBy(filteredSheetCountProducts, cleaned => get(cleaned, 'sheetThickness'));
  const cleanedMixedProducts = map(orderedMixedProducts?.Mixed, item => {
    const { title, ...rest } = item;
    return {
      title: title.replace(/\| White Depron Mixed Case \| Free shipping/, ''),
      ...rest,
    }
  });

  const renderCountAndSize = () => {
    return (
      <div>
        <div className="productSelectSelectWrapper">
          <div>Sheet Count: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetCount}</span></div>
          <div className="cleanedProductsWrapperDesktop">
            {!isEmpty(cleanedSheetCountProducts) && map(Object.keys(cleanedSheetCountProducts), (cleanItem) => (
              <div onClick={() => handleChangeProduct(head(cleanedSheetCountProducts[cleanItem]).id)} className={`cleanedProduct ${cleanItem === selectedCleanedProduct.sheetCount && 'selectedCleanedProduct'}`}>{cleanItem}</div>
            ))}
          </div>
          </div>
        <div className="productSelectSelectWrapper">
          <div>Sheet Size: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetSize}</span></div>
          <div className="cleanedProductsWrapperDesktop">
            {!isEmpty(cleanedSheetCountProducts) && map(orderedSheetSizeProducts, (cleanItem) => (
              <div
                onClick={() => handleChangeProduct(cleanItem.id)}
                className={`cleanedProduct ${cleanItem.sheetSize === selectedCleanedProduct.sheetSize && 'selectedCleanedProduct'}`}
              >
                {cleanItem.sheetSize}
              </div>
            ))}
          </div>
        </div>
    </div>
  )};

  const renderMixedProducts = () => {
    return (
      <div className="cleanedProductsWrapperDesktop" style={{ display: 'flex', flexDirection: 'column' }}>
        {!isEmpty(cleanedMixedProducts) && map(cleanedMixedProducts, (cleanItem) => (
          <div
            onClick={() => handleChangeProduct(cleanItem.id)}
            className={`cleanedProduct ${cleanItem.id === selectedCleanedProduct.id && 'selectedCleanedProduct'}`}
          >
            {cleanItem.title}
          </div>
        ))}
      </div>
    );
  }

  const renderProductSelection = () => {
    if (selectedCleanedProduct.sheetCount || selectedCleanedProduct.sheetThickness === 'Mixed') {
      return (
        <div>
          <div className="productSelectSelectWrapper">
            <div>Sheet Thickness: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetThickness}</span></div>
            <div className="cleanedProductsWrapperDesktop">
              {!isEmpty(cleanedSheetCountProducts) && map(orderedSheetThicknessProducts, (cleanItem) => (
                <div onClick={() => handleChangeProduct(head(cleanedSheetThicknessProducts[cleanItem]).id)} className={`cleanedProduct ${cleanItem === selectedCleanedProduct.sheetThickness && 'selectedCleanedProduct'}`}>{cleanItem}</div>
              ))}
            </div>
          </div>
          {selectedCleanedProduct.sheetCount ? renderCountAndSize() : renderMixedProducts()}
        </div>
      )
    }
    return (
      <div className="productSelectSelectWrapper">
        <div>Item: <span className="mobileSelectedLabel">{get(product, 'title')}</span></div>
        <div className="cleanedProductsWrapperDesktop">
          {!isEmpty(filteredProducts) && map(filteredProducts, (cleanItem) => (
            <div onClick={() => handleChangeProduct(cleanItem.id)} className={`cleanedProduct ${cleanItem.id === product.id && 'selectedCleanedProduct'}`}>{cleanItem.title}</div>
          ))}
        </div>
      </div>
    )
  }

  const renderMobileCountAndSizeSelection = () => {
    return (
      <div>
        <div className="mobileLabel">Sheet Count: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetCount}</span></div>
        <div className="cleanedProductsWrapper">
          {!isEmpty(cleanedSheetCountProducts) && map(Object.keys(cleanedSheetCountProducts), (cleanItem) => (
            <div onClick={() => handleChangeProduct(head(cleanedSheetCountProducts[cleanItem]).id)} className={`cleanedProduct ${cleanItem === selectedCleanedProduct.sheetCount && 'selectedCleanedProduct'}`}>{cleanItem}</div>
          ))}
        </div>
        <div>
          <div className="mobileLabel">Sheet Size: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetSize}</span></div>
          <div className="cleanedProductsWrapper">
            {!isEmpty(cleanedSheetCountProducts) && map(orderedSheetSizeProducts, (cleanItem) => (
              <div
                onClick={() => handleChangeProduct(cleanItem.id)}
                className={`cleanedProduct ${cleanItem.sheetSize === selectedCleanedProduct.sheetSize && 'selectedCleanedProduct'}`}
              >
                {cleanItem.sheetSize}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const renderMobileMixedProducts = () => {
    return (
      <div className="cleanedProductsWrapper">
        {renderMixedProducts()}
      </div>
    )
  }

  return (
    <div>
      <div className="mobileProductSelectWrapper">
        <div className="productSelectDetailsTitleMobileWrapper">
          <div className="productSelectDetailsTitleMobile">{filteredProducts.length === 1 ? get(product, 'title') : get(collectionItem, 'title')}</div>
        </div>
        <div>
          <Swiper
            pagination={{
              "clickable": true
            }}
            loop
          >
            {_.map(images, (image) => (
                <SwiperSlide><div className="mobilePhotoInPhotosSelectionWrapper"><img className="mobilePhotoInPhotosSelection" src={get(image, 'src')} alt={get(image, 'altText')} /></div></SwiperSlide>
              )
            )}
          </Swiper>
        </div>
        <div className="spacer" />
        {(selectedCleanedProduct.sheetCount  || selectedCleanedProduct.sheetThickness === 'Mixed') ? (
          <div>
            <div className="mobileLabel">Sheet Thickness: <span className="mobileSelectedLabel">{selectedCleanedProduct.sheetThickness}</span></div>
            <div className="cleanedProductsWrapper">
              {!isEmpty(cleanedSheetCountProducts) && map(orderedSheetThicknessProducts, (cleanItem) => (
                <div onClick={() => handleChangeProduct(head(cleanedSheetThicknessProducts[cleanItem]).id)} className={`cleanedProduct ${cleanItem === selectedCleanedProduct.sheetThickness && 'selectedCleanedProduct'}`}>{cleanItem}</div>
              ))}
            </div>
            {selectedCleanedProduct.sheetCount ? renderMobileCountAndSizeSelection() : renderMobileMixedProducts()}
          </div>
        ) : (
          <div>
            <div className="mobileLabel">Item: <span className="mobileSelectedLabel">{get(product, 'title')}</span></div>
            <div className="cleanedProductsWrapper">
              {!isEmpty(filteredProducts) && map(filteredProducts, (cleanItem) => (
                <div onClick={() => handleChangeProduct(cleanItem.id)} className={`cleanedProduct ${cleanItem.id === product.id && 'selectedCleanedProduct'}`}>{cleanItem.title}</div>
              ))}
            </div>
          </div>
        )}
        <div className="mobileProductSelectAddToCartWrapper">
          <div className="productSelectAddToCartWrapper">
            <div className="productSelectAddToCart">
              <div className="produceSelectPrice productSelectAddToCartPrice">${getItemTotalPrice(product)}</div>
                {get(product, 'availableForSale') &&
                  <div>
                    <Select defaultValue={1} className="numberSelect">
                      {/* To buy, select Size */}
                      {_.map(numberSelect, n => <Option value={n}>Qty: ({n})</Option>)}
                    </Select>
                    <div className="buttonWrapper productSelectButtonWrapper">
                      <button className="button productSelectButton"
                        onClick={() => addItemToCheckout(_.get(product, 'variants[0].id'), 1)}
                      >
                        <span>Add to Cart</span>
                      </button>
                    </div>
                    <div>
                      <div><LockOutlined /> Secure transaction</div>
                      <div>(Shopify and Paypal)</div>
                    </div>
                  </div>
                }
            </div>
          </div>
        </div>
        <div className="spacer" />
        <div className="mobileProductWrapper">
          <div className="productSelectDetailsTitleMobile">
            Product Description
          </div>
          <div className="productSelectDetails">
            {product && <ul><li>{ReactHtmlParser(get(product, 'descriptionHtml'))}</li></ul>}
            {ReactHtmlParser(get(collectionItem, 'descriptionHtml'))}
          </div>
        </div>
      </div>
      <div className="productSelectWrapper">
        <div className="productSelect">
          <div className="photosSelection">
            {_.map(images, (image, index) =>
              <div className={`photoInPhotosSelectionWrapper ${index === hoveredImage && 'hoveredSelectionPhoto'}`} onMouseEnter={() => setHoveredImage(index)}>
                <img className="photoInPhotosSelection" src={get(image, 'src')} alt={get(image, 'altText')} />
              </div>
            )}
          </div>
          <div className="productSelectMainPhotoWrapper">
            <img className="productSelectMainPhoto" src={get(images, `[${hoveredImage}].src`)} alt="" />
          </div>
          <div className="productSelectDetailsWrapper">
            <div className="productSelectDetails">
              <div className="productSelectDetailsTitle">
                {productTitle}
              </div>
              <div className="produceSelectPriceWrapper">
                <div>Price:&nbsp;</div>
                <div className="produceSelectPrice">${getItemTotalPrice(product)}</div>
              </div>
              {renderProductSelection()}
              <div className="productSelectDetails">
                {product && <ul><li>{ReactHtmlParser(get(product, 'descriptionHtml'))}</li></ul>}
                {ReactHtmlParser(get(collectionItem, 'descriptionHtml'))}
              </div>
            </div>
            <div className="productSelectAddToCartWrapper">
              <div className="productSelectAddToCart">
                <div className="produceSelectPrice productSelectAddToCartPrice">${getItemTotalPrice(product)}</div>
                  {get(product, 'availableForSale') &&
                    <div>
                      <Select defaultValue={1} className="numberSelect">
                        {/* To buy, select Size */}
                        {_.map(numberSelect, n => <Option value={n}>Qty: ({n})</Option>)}
                      </Select>
                      <div className="buttonWrapper productSelectButtonWrapper">
                        <button className="button productSelectButton"
                          onClick={() => addItemToCheckout(_.get(product, 'variants[0].id'), 1)}
                        >
                          <span>Add to Cart</span>
                        </button>
                      </div>
                      <div>
                        <div><LockOutlined /> Secure transaction</div>
                        <div>(Shopify and Paypal)</div>
                      </div>
                    </div>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSelect;
